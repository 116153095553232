var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
//
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from './CustomIcons';
// ----------------------------------------------------------------------
var COLORS = ['info', 'success', 'warning', 'error'];
export default function Alert(theme) {
    var isLight = theme.palette.mode === 'light';
    var rootStyle = function (ownerState) {
        var standardVariant = ownerState.variant === 'standard';
        var filledVariant = ownerState.variant === 'filled';
        var outlinedVariant = ownerState.variant === 'outlined';
        var colorStyle = COLORS.map(function (color) { return (__assign({}, (ownerState.severity === color && __assign(__assign(__assign({}, (standardVariant && {
            color: theme.palette[color][isLight ? 'darker' : 'lighter'],
            backgroundColor: theme.palette[color][isLight ? 'lighter' : 'darker'],
            '& .MuiAlert-icon': {
                color: theme.palette[color][isLight ? 'main' : 'light'],
            },
        })), (filledVariant && {
            color: theme.palette[color].contrastText,
            backgroundColor: theme.palette[color].main,
        })), (outlinedVariant && {
            color: theme.palette[color][isLight ? 'dark' : 'light'],
            border: "solid 1px ".concat(theme.palette[color].main),
            '& .MuiAlert-icon': {
                color: theme.palette[color].main,
            },
        }))))); });
        return __spreadArray([], colorStyle, true);
    };
    return {
        MuiAlert: {
            defaultProps: {
                iconMapping: {
                    info: _jsx(InfoIcon, {}),
                    success: _jsx(SuccessIcon, {}),
                    warning: _jsx(WarningIcon, {}),
                    error: _jsx(ErrorIcon, {}),
                },
            },
            styleOverrides: {
                root: function (_a) {
                    var ownerState = _a.ownerState;
                    return rootStyle(ownerState);
                },
                icon: {
                    opacity: 1,
                },
            },
        },
        MuiAlertTitle: {
            styleOverrides: {
                root: {
                    marginBottom: theme.spacing(0.5),
                },
            },
        },
    };
}
